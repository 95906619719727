import { useEffect } from 'react';
import { getLocalLanguage, LoggerInstance } from '@vfit/shared/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';

const PreCheckout = () => {
  const currentLang = getLocalLanguage();
  const authorizeData = getFromLocalStorageByKey('authorize');

  const paymentGateway = (authorizationParameters: any) => {
    const actionParam = {
      ...authorizationParameters,
      ...(currentLang &&
        currentLang === 'en' && {
          lang: 'ENG',
        }),
      ORDERID: authorizationParameters?.siaOrderId,
    };

    // let queryParam = '';
    const skipUrl = ['SIAORDERID', 'SIASIMULATORMODE', 'URLSIA', 'URL'];
    const urlSIA = actionParam.urlSia;
    const formEl = document.forms['payGateway'];

    // Fill form values from outcome parameters
    // eslint-disable-next-line
    for (const parameter in actionParam) {
      if (actionParam?.[parameter]?.toString() && skipUrl.indexOf(parameter.toUpperCase()) === -1) {
        // queryParam = `${queryParam}&${parameter.toUpperCase()}=${actionParam[parameter]}`;
        const inputEl = document.createElement('input');
        inputEl.setAttribute('type', 'hidden');
        inputEl.setAttribute('name', parameter.toUpperCase());
        inputEl.setAttribute('value', actionParam[parameter].toString());
        formEl.appendChild(inputEl);
      }
    }

    LoggerInstance.debug('paymentGateway formEl : ', formEl);
    formEl.action = urlSIA;
    LoggerInstance.debug('SIA', formEl);
    formEl.submit();
  };

  useEffect(() => {
    if (authorizeData) {
      LoggerInstance.debug(
        'paymentGateway useEffect actionParam : ',
        authorizeData?.authorizationParameters
      );
      paymentGateway(authorizeData?.authorizationParameters);
    }
  }, []);

  return (
    <div>
      <form name="payGateway" method="POST" encType="application/x-www-form-urlencoded" />
    </div>
  );
};

export default PreCheckout;
